<template>
  <div class="addPowerBox">
    <NavBar title="算力增速">
      <template #right>
        <TextBox :font-size="26" @click.native="onOrder">我的订单</TextBox>
      </template>
    </NavBar>
    <div class="contentBox">
      <Space :height="21"></Space>
      <div class="df aic">
        <TextBox color="#2C395B" :font-size="32">算力增速包</TextBox>
        <Space :width="20"></Space>
        <van-image
          :width="parseInt($pxToPxRatio(63), 10)"
          :height="parseInt($pxToPxRatio(26), 10)"
          fit="cover"
          :src="require('@/assets/images/personal/addPower/a1.png')"
        />
      </div>
      <Space :height="19"></Space>
      <div class="inputCard card">
        <Space :height="83"></Space>
        <div class="titleBox">
          <div>
            <div class="price">{{ detail.totalPower }}T</div>
            <div class="title">我的总存储</div>
          </div>
          <div>
            <div class="price yellow">{{ detail.effectivePower }}T</div>
            <div class="title">当前有效算力</div>
          </div>
        </div>
        <Space :height="36"></Space>
        <div class="titleBox">
          <div>
            <div class="price">{{ detail.acceleratePower }}T</div>
            <div class="title">最大增速算力</div>
          </div>
          <div>
            <div class="price">{{ detail.growthPower }}T</div>
            <div class="title">增速中算力</div>
          </div>
        </div>
        <van-divider />
        <Space :height="47"></Space>
        <div class="inputBox">
          <van-stepper v-model="input" :min="min" :max="detail.acceleratePower" integer @change="changeInput" />
          <div class="unit">TiB</div>
        </div>
        <Space :height="70"></Space>
        <div class="introBox">
          <div>
            <div>增速量</div>
            <div>{{ !Number(detail.acceleratePower) ? 0 : input }} TiB</div>
          </div>
          <div>
            <div>增速时间</div>
            <div>T+{{ detail.accelerateType }}</div>
          </div>
          <div>
            <div>所需质押</div>
            <div>{{ $priceFilter($accMul(input, detail.acceleratePerFil), 2) }} FIL</div>
          </div>
          <div>
            <div>GAS费用</div>
            <div>{{ $priceFilter($accMul(input, detail.acceleratePerGsa), 2) }} FIL</div>
          </div>
        </div>
      </div>
      <Space :height="31"></Space>
      <div class="tipBox card">
        <div class="title">增速说明</div>
        <van-divider />
        <div class="value">
          ·算力增速包根据一期二期订单分别做增速，不可混合增速。<br />
          ·有效算力达到总存储的50%时才可参与增速，最大增速算力为总存储的70%。<br />
          ·举例：增速时间为T+10，增速量为1时则表示每天增加有效算力0.1T，十天完成增加1T有效算力。<br />
          ·增速中的算力=已购买加速包算力总和-已增速算力总和。<br />
          ·关于《增速说明》的最终解释权归HY所有。<br />
        </div>
      </div>
    </div>
    <Space :height="198"></Space>
    <van-button
      class="fixedBottom bg"
      type="primary"
      block
      @click="submitAfter"
      :loading="$store.state.submitLoading"
      :disabled="!Number(detail.acceleratePower)"
    >
      立即增速
    </van-button>
    <van-dialog v-model="dialog" close-on-click-overlay :show-cancel-button="true" @confirm="submit" title="订单确认">
      <div class="dialogBox">
        <div>
          <span>增速量</span>
          <span class="weightColor">{{ input }} TiB</span>
        </div>
        <div>
          <span>增速时间</span>
          <span>T+{{ detail.accelerateType }}</span>
        </div>
        <div>
          <span>所需质押</span>
          <span
            >{{ $priceFilter($accMul(input, detail.acceleratePerFil + detail.acceleratePerGsa), 2, true) }} FIL</span
          >
        </div>
        <van-divider />
        <Space :height="15"></Space>
        <div>
          <span>支付账户</span>
          <span style="color: #e28710">{{ $store.state.personal.userActive === 0 ? '一期账户' : '二期账户' }}</span>
        </div>
        <div>
          <span>账户余额</span>
          <span style="color: #e28710">{{ $priceFilter(coinDetail.available, 2, true) }} FIL</span>
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: {},
  data() {
    return {
      input: 1,
      min: 1,
      detail: {
        totalPower: 0,
        effectivePower: 0,
        acceleratePower: 0,
        accelerateType: 0,

        acceleratePerFil: 0,
        acceleratePerGsa: 0,

        goodsId: 0,
      },
      dialog: false,
      coinDetail: null,
    };
  },
  created() {
    this.$store.commit('setBodyColor');
    this.getDetail();
    this.getCoinList();
  },
  // mounted () {},
  methods: {
    getCoinList() {
      this.$http('get', '/v1/assets/coinInfo').then(res => {
        this.coinList = res.data;
        res.data.forEach((item, index) => {
          if (item.name.toUpperCase() === 'FIL') {
            this.getUserAssets(item.id);
          }
        });
      });
    },
    getUserAssets(coinId) {
      this.$http('post', '/v1/user/userAssetsInfo', {
        coinId: coinId,
        // 1一期 3二期
        walletAccountId: this.$store.state.personal.userActive ? 3 : 1,
      }).then(res => {
        this.coinDetail = res.data;
      });
    },
    changeInput() {
      this.$nextTick(() => {
        if (this.input > this.detail.acceleratePower) {
          this.input = this.detail.acceleratePower;
          this.$forceUpdate();
        }
      });
    },
    onOrder() {
      this.$router.push('/locked/order?i=2');
    },
    getDetail() {
      this.$http('get', '/v1/accelerate/accelerateDdetails', {
        goodsId: this.$store.state.personal.userActive + 1,
      }).then(res => {
        this.detail = res.data;
      });
    },
    submitAfter() {
      this.dialog = true;
    },
    submit: _.debounce(function() {
      if (this.input < 1) {
        this.$toast('增速量最小为1');
        return;
      }
      if (this.input > this.detail.acceleratePower) {
        this.$toast(`最大值为${this.detail.acceleratePower}`);
        return;
      }

      this.$store.commit('setSubmitLoading', true);
      this.$http('post', '/v1/accelerate/accelerateUserBuy', {
        acceleratePower: this.input,
        goodsId: this.$store.state.personal.userActive + 1,
      })
        .then(res => {
          this.$toast('增速成功');
          this.$router.push('/locked/order?i=2');
        })
        .all(() => {
          this.$store.commit('setSubmitLoading', false);
        });
    }),
  },
  // watch: {},
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
/deep/ .van-button--disabled {
  opacity: 1;
  &::before {
    display: block;
    opacity: 0.6 !important;
  }
}
.addPowerBox {
  > .contentBox {
    background: url('~@/assets/images/personal/addPower/a2.png') no-repeat left top;
    background-size: contain;
  }
  .card {
    background: #ffffff;
    border-radius: 8px;
  }

  .inputCard {
    .titleBox {
      padding: 0 113px 41px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        width: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .price {
        font-size: 44px;
        font-weight: bold;
        color: #2c395b;
        line-height: 56px;

        &.yellow {
          color: #e28710;
        }
      }
      .title {
        font-size: 24px;
        font-weight: 400;
        color: #9ea2b5;
        line-height: 33px;

        margin-top: 11px;
      }
    }
    .inputBox {
      padding-left: 40px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      /deep/ .van-stepper {
        border: 1px solid #d7dce3;
        border-radius: 8px;
        .van-stepper__minus,
        .van-stepper__plus {
          width: 75px;
          height: 66px;
          background-color: #f6f8fb;
        }
        .van-stepper__minus--disabled,
        .van-stepper__plus--disabled {
          background-color: #f7f8fa;
        }
        .van-stepper__minus::before,
        .van-stepper__plus::before {
          width: 30%;
        }
        .van-stepper__minus::after,
        .van-stepper__plus::after {
          height: 30%;
        }
        .van-stepper__input {
          width: 218px;
          height: 66px;
          margin: 0;
          background-color: #f6f8fb;
          border-left: 1px solid #d7dce3;
          border-right: 1px solid #d7dce3;
        }
      }
      .unit {
        font-size: 24px;
        font-weight: 400;
        color: #586586;
        line-height: 33px;
        margin-left: 10px;
      }
    }
    .introBox {
      padding: 0 54px 12px 49px;
      > div {
        margin-bottom: 30px;
        font-size: 28px;
        font-weight: 400;
        color: #2c395b;
        line-height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .tipBox {
    .title {
      height: 115px;
      display: flex;
      align-items: center;
      padding-left: 30px;

      font-size: 28px;
      font-weight: 400;
      color: #2c395b;
      line-height: 40px;
    }
    .value {
      padding: 21px 30px;

      font-size: 24px;
      font-weight: 400;
      color: #9ea2b5;
      line-height: 58px;
    }
  }
}
.dialogBox {
  padding: 58px 50px 37px 60px;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    span {
      font-size: 28px;
      color: @intro-color;
      &:last-child {
        &.weightColor {
          color: #071c5e;
        }
        color: #2c395b;
      }
    }
  }
}
/deep/ .van-dialog {
  .van-dialog__header {
    border-bottom: 1px solid #f1f3f4;
    padding: 0;
    height: 106px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 32px;
    font-weight: 500;
    color: #0e0d51;
    line-height: 45px;
  }
  .van-dialog__cancel {
    background-color: #9797d5;
    color: white;
    border: 1px solid #9797d5;
  }
}
</style>
